export const PAYMENT_JOIN_PRODUCT_TOKEN = {
  name: "joinToken",
};

export const NEARBY_FACILITES = {
  name: "EqxWeb.FacilityIDs",
  expiration: 86400, // in seconds = 1 day
};

export const USER_LOCATION = {
  name: "EqxWeb.Location",
  expiration: 1800, // in seconds = 30 minutes
};

export const USER_SESSION_ACCESS_TOKEN = {
  name: "EqxWeb.bavToken",
};

export const USER_INFO = {
  name: "userInfo",
};
