export const AVAILABLE_USER_STATES_BY_COUNTRY = {
  US: [
    { val: "AL", label: "AL" },
    { val: "AK", label: "AK" },
    { val: "AZ", label: "AZ" },
    { val: "AR", label: "AR" },
    { val: "CA", label: "CA" },
    { val: "CO", label: "CO" },
    { val: "CT", label: "CT" },
    { val: "DE", label: "DE" },
    { val: "DC", label: "DC" },
    { val: "FL", label: "FL" },
    { val: "GA", label: "GA" },
    { val: "HI", label: "HI" },
    { val: "ID", label: "ID" },
    { val: "IL", label: "IL" },
    { val: "IN", label: "IN" },
    { val: "IA", label: "IA" },
    { val: "KS", label: "KS" },
    { val: "KY", label: "KY" },
    { val: "LA", label: "LA" },
    { val: "ME", label: "ME" },
    { val: "MD", label: "MD" },
    { val: "MA", label: "MA" },
    { val: "MI", label: "MI" },
    { val: "MN", label: "MN" },
    { val: "MS", label: "MS" },
    { val: "MO", label: "MO" },
    { val: "MT", label: "MT" },
    { val: "NE", label: "NE" },
    { val: "NV", label: "NV" },
    { val: "NH", label: "NH" },
    { val: "NJ", label: "NJ" },
    { val: "NM", label: "NM" },
    { val: "NY", label: "NY" },
    { val: "NC", label: "NC" },
    { val: "ND", label: "ND" },
    { val: "OH", label: "OH" },
    { val: "OK", label: "OK" },
    { val: "OR", label: "OR" },
    { val: "PA", label: "PA" },
    { val: "RI", label: "RI" },
    { val: "SC", label: "SC" },
    { val: "SD", label: "SD" },
    { val: "TN", label: "TN" },
    { val: "TX", label: "TX" },
    { val: "UT", label: "UT" },
    { val: "VT", label: "VT" },
    { val: "VA", label: "VA" },
    { val: "WA", label: "WA" },
    { val: "WV", label: "WV" },
    { val: "WI", label: "WI" },
    { val: "WY", label: "WY" },
  ],
  UK: [
    { value: "HAW", label: "HAW" },
    { value: "I0", label: "I0" },
    { value: "I1", label: "I1" },
    { value: "I2", label: "I2" },
    { value: "I3", label: "I3" },
    { value: "I4", label: "I4" },
    { value: "I5", label: "I5" },
    { value: "I6", label: "I6" },
    { value: "I7", label: "I7" },
    { value: "I8", label: "I8" },
    { value: "I9", label: "I9" },
    { value: "IB", label: "IB" },
    { value: "IC", label: "IC" },
    { value: "IE", label: "IE" },
    { value: "IF", label: "IF" },
    { value: "IG", label: "IG" },
    { value: "IH", label: "IH" },
    { value: "II", label: "II" },
    { value: "IJ", label: "IJ" },
    { value: "IK", label: "IK" },
    { value: "IM", label: "IM" },
    { value: "IO", label: "IO" },
    { value: "IP", label: "IP" },
    { value: "IQ", label: "IQ" },
    { value: "IR", label: "IR" },
    { value: "IS", label: "IS" },
    { value: "IT", label: "IT" },
    { value: "IU", label: "IU" },
    { value: "IV", label: "IV" },
    { value: "IW", label: "IW" },
    { value: "IX", label: "IX" },
    { value: "IY", label: "IY" },
    { value: "IZ", label: "IZ" },
    { value: "J0", label: "J0" },
    { value: "J1", label: "J1" },
    { value: "J2", label: "J2" },
    { value: "J3", label: "J3" },
    { value: "J4", label: "J4" },
    { value: "J5", label: "J5" },
    { value: "J6", label: "J6" },
    { value: "J7", label: "J7" },
    { value: "J8", label: "J8" },
    { value: "J9", label: "J9" },
    { value: "JA", label: "JA" },
    { value: "JB", label: "JB" },
    { value: "JC", label: "JC" },
    { value: "JD", label: "JD" },
    { value: "JE", label: "JE" },
    { value: "JF", label: "JF" },
    { value: "JG", label: "JG" },
    { value: "JH", label: "JH" },
    { value: "JI", label: "JI" },
    { value: "JJ", label: "JJ" },
    { value: "JK", label: "JK" },
    { value: "JL", label: "JL" },
    { value: "JM", label: "JM" },
    { value: "JN", label: "JN" },
    { value: "JO", label: "JO" },
    { value: "JP", label: "JP" },
    { value: "JQ", label: "JQ" },
    { value: "JR", label: "JR" },
    { value: "JS", label: "JS" },
    { value: "JT", label: "JT" },
    { value: "JU", label: "JU" },
    { value: "JV", label: "JV" },
    { value: "JW", label: "JW" },
    { value: "JX", label: "JX" },
    { value: "JY", label: "JY" },
    { value: "JZ", label: "JZ" },
    { value: "K0", label: "K0" },
    { value: "K1", label: "K1" },
    { value: "K2", label: "K2" },
    { value: "K3", label: "K3" },
    { value: "K4", label: "K4" },
    { value: "K5", label: "K5" },
    { value: "K6", label: "K6" },
    { value: "K7", label: "K7" },
    { value: "K8", label: "K8" },
    { value: "K9", label: "K9" },
    { value: "KA", label: "KA" },
    { value: "KB", label: "KB" },
    { value: "KC", label: "KC" },
    { value: "KD", label: "KD" },
    { value: "KE", label: "KE" },
    { value: "KF", label: "KF" },
    { value: "KG", label: "KG" },
    { value: "KH", label: "KH" },
    { value: "KI", label: "KI" },
    { value: "KJ", label: "KJ" },
    { value: "KK", label: "KK" },
    { value: "KL", label: "KL" },
    { value: "KM", label: "KM" },
    { value: "KN", label: "KN" },
    { value: "KO", label: "KO" },
    { value: "KP", label: "KP" },
    { value: "KQ", label: "KQ" },
    { value: "KR", label: "KR" },
    { value: "KT", label: "KT" },
    { value: "KU", label: "KU" },
    { value: "KV", label: "KV" },
    { value: "KW", label: "KW" },
    { value: "KX", label: "KX" },
    { value: "KZ", label: "KZ" },
    { value: "L0", label: "L0" },
    { value: "L1", label: "L1" },
    { value: "L2", label: "L2" },
    { value: "L3", label: "L3" },
    { value: "L4", label: "L4" },
    { value: "L5", label: "L5" },
    { value: "L6", label: "L6" },
    { value: "L7", label: "L7" },
    { value: "L8", label: "L8" },
    { value: "L9", label: "L9" },
    { value: "LB", label: "LB" },
    { value: "LC", label: "LC" },
    { value: "LD", label: "LD" },
    { value: "LE", label: "LE" },
    { value: "LF", label: "LF" },
    { value: "LG", label: "LG" },
    { value: "LH", label: "LH" },
    { value: "LI", label: "LI" },
    { value: "LJ", label: "LJ" },
    { value: "LK", label: "LK" },
    { value: "LL", label: "LL" },
    { value: "LM", label: "LM" },
    { value: "LN", label: "LN" },
    { value: "LO", label: "LO" },
    { value: "LP", label: "LP" },
    { value: "LQ", label: "LQ" },
    { value: "LR", label: "LR" },
    { value: "LS", label: "LS" },
    { value: "LT", label: "LT" },
    { value: "LU", label: "LU" },
    { value: "LV", label: "LV" },
    { value: "LW", label: "LW" },
    { value: "LX", label: "LX" },
    { value: "LY", label: "LY" },
    { value: "LZ", label: "LZ" },
    { value: "M0", label: "M0" },
    { value: "M1", label: "M1" },
    { value: "M2", label: "M2" },
    { value: "M3", label: "M3" },
    { value: "M4", label: "M4" },
    { value: "M5", label: "M5" },
    { value: "M6", label: "M6" },
    { value: "M7", label: "M7" },
  ],
  CA: [
    { value: "AB", label: "AB" },
    { value: "BC", label: "BC" },
    { value: "MB", label: "MB" },
    { value: "NB", label: "NB" },
    { value: "NL", label: "NL" },
    { value: "NT", label: "NT" },
    { value: "NS", label: "NS" },
    { value: "NU", label: "NU" },
    { value: "ON", label: "ON" },
    { value: "PE", label: "PE" },
    { value: "QC", label: "QC" },
    { value: "SK", label: "SK" },
    { value: "YT", label: "YT" },
  ],
};
