export const ALL_ACCESS_FITNESS_2 = {
  id: 2221,
  name: "All Access Fitness 2",
};

export const BOSTON_ACCESS = {
  id: 2,
  name: "Boston Access",
};

export const CALIFORNIA_ACCESS = {
  id: 12,
  name: "California Access",
};

export const CANADA_ACCESS = {
  id: 14,
  name: "Canada Access",
};

export const CHICAGO_ACCESS = {
  id: 3,
  name: "Chicago Access",
};

export const DALLAS_ACCESS = {
  id: 4,
  name: "Dallas Access",
};

export const DESTINATION_ACCESS = {
  id: 5,
  name: "Destination Access",
};

export const DESTINATION_ACCESS_2 = {
  id: 2396,
  name: "Destination Access 2",
};

export const DESTINATION_ACCESS_HY = {
  id: 2397,
  name: "Destination Access HY",
};

export const DESTINATION_UK_ACCESS = {
  id: 978,
  name: "Destination UK Access",
};

export const E_ACCESS = {
  id: 7,
  name: "E Access",
};

export const EAST_VALLEY_DOWNTOWN_LA_ACCESS = {
  id: 16,
  name: "East Valley Downtown LA Access",
};

export const EQUINOX_PLUS_ACCESS = {
  id: 2056,
  name: "Equinox+ Access",
};

export const FLORIDA_ACCESS = {
  id: 8,
  name: "Florida Access",
};

export const METRO_DC_ACCESS = {
  id: 9,
  name: "Metro D.C. Access",
};

export const NORTHERN_CALIFORNIA_ACCESS = {
  id: 11,
  name: "Northern California Access",
};

export const ORANGE_COUNTY_ACCESS = {
  id: 13,
  name: "Orange County Access",
};

export const SELECT_ACCESS = {
  id: 15,
  name: "Select Access",
};

export const SOUTHERN_CALIFORNIA_ACCESS = {
  id: 1724,
  name: "Southern California Access",
};

const PLANS = [
  ALL_ACCESS_FITNESS_2,
  BOSTON_ACCESS,
  CALIFORNIA_ACCESS,
  CANADA_ACCESS,
  CHICAGO_ACCESS,
  DALLAS_ACCESS,
  DESTINATION_ACCESS,
  DESTINATION_ACCESS_2,
  DESTINATION_ACCESS_HY,
  DESTINATION_UK_ACCESS,
  E_ACCESS,
  EAST_VALLEY_DOWNTOWN_LA_ACCESS,
  EQUINOX_PLUS_ACCESS,
  FLORIDA_ACCESS,
  METRO_DC_ACCESS,
  NORTHERN_CALIFORNIA_ACCESS,
  ORANGE_COUNTY_ACCESS,
  SELECT_ACCESS,
  SOUTHERN_CALIFORNIA_ACCESS,
];

const byId = {};

PLANS.forEach((plan) => {
  byId[plan.id] = plan;
});

export { byId };

export default PLANS;
