import NProgress from "nprogress";
import React from "react";

import { bar, nProgressWrapper } from "./index.module.scss";

class ProgressBar extends React.Component {
  componentDidMount() {
    NProgress.configure({
      parent: `#${nProgressWrapper}`,
      speed: 650,
      showSpinner: false,
      template: `<div class=${bar} role="bar"><div class="peg"></div></div></div>`,
    });
  }
  render() {
    return <div id={nProgressWrapper} />;
  }
}

export default ProgressBar;
