/*
 * This is a sample customization showing that configuration fields can
 * be patched/overridden if required. (The base/default environment
 * configuration can be found in ./constants.js for structure. Items written
 * below take precedent and are applied on top of the default values.)
 *
 * The key (e.g. localhost below) represents a matcher for env.environment variable
 * value, and the customization for the key/environment will only be applied when
 * the env.environment value is equal.
 *
 export const envCustomizations = {
  localhost: {
    analytics: {
      reportSuiteID: "eqeqxdpwprod",
    },
  },
};
*/

// Overrides for production.
export const envCustomizations = {
  production: {
    analytics: {
      reportSuiteID: "eqeqxdpwprod",
    },
  },
};

// Overrides to set specific ttl and threshold for refresh tokens on QA environments.
// TODO: Nice-to-have -> wildcards (the overrides below could be: ["qa, "qa-*"])
[
  "qa",
  "qa-armstrong",
  "qa-anquetil",
  "qa-froome",
  "qa-hinault",
  "qa-indurain",
  "qa-lemond",
  "qa-maintenance",
  "qa-mercx",
].forEach((qaEnv) => {
  let updatedCustomizations = envCustomizations[qaEnv];
  if (!updatedCustomizations) {
    updatedCustomizations = {};
  }
  if (!updatedCustomizations.session) {
    updatedCustomizations.session = {};
  }

  envCustomizations[qaEnv] = updatedCustomizations;
});

// Flag to auto-initialize the ApiClient instance. (see: initializeApiClient in init.js)
export const autoInitialize = true;
